




















































































































import { Component, Vue } from "vue-property-decorator";
import Typewriter from "@/components/Typewriter.vue";
import About from "@/views/About.vue";
import Experience from "@/views/Experience.vue";
import Projects from "@/views/Projects.vue";
import Contact from "@/views/Contact.vue";

@Component({
  components: {
    Typewriter,
    About,
    Experience,
    Projects,
    Contact
  }
})
export default class Home extends Vue {
  toRotate: string[] = [
    "Software Engineer",
    "Web Designer",
    "Project Manager",
    "Digital Marketer",
    "Leader",
    "Entrepreneur"
  ];

  getTypewriterText() {
    const typewriter = this.$refs.typewriter as Typewriter;
    if (typewriter) return typewriter.text;
  }

  mounted() {
    this.$forceUpdate();
  }
}
