































































import { Component, Vue } from "vue-property-decorator";
import JobModel from "@/models/job";

export default class Experience extends Vue {
  jobsToShow: number = 3;
  jobsExpanded: boolean = false;
  jobs: JobModel[] = [
    {
      title: "Sr. Engineering Manager",
      company: "Expedia Group, Inc.",
      description: "TBD",
      startDate: "03/2022",
      endDate: "Current"
    },
    {
      title: "Director of Engineering",
      company: "Scotsman Guide, Inc.",
      description:
        "Managed the Engineering department, including development, design, and data teams. I rebuilt the department from the ground up, establishing policies and processes to greatly improve our product development pipeline as well as employee career development. I oversaw the SDLC processes for the full remake of all our existing digital products, as well as being involved in the business teams conceptualizing new products. I researched various state and federal laws regarding privacy and cyber security then implemented policies and procedures to ensure we stayed in compliance. I was promoted from Engineering Manager to Director in March 2021.",
      startDate: "02/2018",
      endDate: "01/2022"
    },
    {
      title: "Sr Software Engineer",
      company: "Market Leader / Zillow",
      description:
        "Acted in a lead developer capacity on several projects, including developing our hybrid mobile application using AngularJS and Ionic. I saw this project through from conception in a decision-making role, while also leading developers and setting project standards. I was responsible for creating coding standards, functional specs, design documents, and other documentation. I acted as a mentor and helped other engineers learn the technology layers.",
      startDate: "09/2014",
      endDate: "02/2018"
    },
    {
      title: "Sr Software Engineer",
      company: "Front Gate Tickets",
      description:
        "Assisted in the development of a new AngularJS project while working in a leadership and decision-making role to assist staff working concerts and other major events. Participated and gave guidance during the conceptualization stage as well as provided insight into problem areas.",
      startDate: "07/2013",
      endDate: "03/2014"
    },
    {
      title: "Software Engineer",
      company: "Home Improvement Leads",
      description:
        "ked independently as well as in a mentoring capacity to junior developers, I was responsible for development of lead generation websites and tools. Developed frontend, middle tier, and backend systems and provided the architecture for a lead buying platform.",
      startDate: "03/2012",
      endDate: "07/2013"
    },
    {
      title: "CEO",
      company: "Sudo Media Group",
      description:
        "Developed email marketing technologies to assist in mass outgoing email-marketing campaigns. I was an owner and lead developer of the company and provided the leadership and knowledge to assist developers in a state-of-the-art automated email solution.",
      startDate: "01/2011",
      endDate: "03/2012"
    },
    {
      title: "Email Marketing Director",
      company: "eComLeads, LLC",
      description:
        "Acted in a leadership role in leading successful email marketing campaigns. I lead a team of developers, designers, and others to create email technologies. I was responsible for the development of a system that allowed us to manage website themes on hundreds of our domains.",
      startDate: "03/2009",
      endDate: "10/2011"
    },
    {
      title: "Owner",
      company: "Texas IT Consulting & Services",
      description:
        "As an independent contractor, I specialized in medical technology and compliance. I managed a team of developers in India for the creation of an electronic medical records solution. I lead the development of tools that allow clients to view and measure DICOM images from MRI equipment.",
      startDate: "02/2007",
      endDate: "03/2009"
    }
  ];

  toggleExpand() {
    this.jobsExpanded = !this.jobsExpanded;
    this.$forceUpdate();

    if (!this.jobsExpanded) {
      document.querySelector("#experience")!.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
