





























































import { Vue, Component } from "vue-property-decorator";
import Home from "./Home.vue";

@Component
export default class About extends Vue {
  getTypewriterText: Function = (this.$parent as Home).getTypewriterText;
}
