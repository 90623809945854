


































































































import { Vue, Component } from "vue-property-decorator";

@Component
export default class extends Vue {
  currentYear: number = 2019;

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  }

  mounted() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function(this: HTMLElement, e: Event) {
        const href = this.getAttribute("href")!;
        if (href.indexOf("#") !== 0) return;
        e.preventDefault();

        document.querySelector(href)!.scrollIntoView({
          behavior: "smooth"
        });
      });
    });
  }
}
